'use client';

import PropTypes from 'prop-types';
// icons
// next
import NextLink from 'next/link';
// @mui
import { Hidden, Link, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import NavDesktopMenu from './NavDesktopMenu';
import {HOST_WEBSITE} from "../../../config-global";
// components


// ----------------------------------------------------------------------

NavDesktop.propTypes = {
  isTransparent: PropTypes.bool,
  navConfig: PropTypes.array.isRequired,
  currentUrl: PropTypes.string,
  isScrolling: PropTypes.bool,
};

export default function NavDesktop({ isTransparent, navConfig, currentUrl, isScrolling }) {
  return (
    <Stack
      direction='row'
      spacing={{md: 3, lg: 5}}
      sx={{
        ml: 8,
        color: 'text.secondary',
        whiteSpace: 'nowrap',
        ...(isTransparent && {
          color: 'inherit',
        }),
        ...(isScrolling && {
          color: 'text.secondary',
        }),
      }}
    >
      {navConfig.map((link) => (
        <NavItemDesktop
          key={link.title}
          item={link}
          isScrolling={isScrolling}
          isTransparent={isTransparent}
          currentUrl={currentUrl}
        />
      ))}
    </Stack>
  );
}

// ----------------------------------------------------------------------

NavItemDesktop.propTypes = {
  isScrolling: PropTypes.bool,
  isTransparent: PropTypes.bool,
  item: PropTypes.shape({
    children: PropTypes.array,
    path: PropTypes.string,
    title: PropTypes.string,
    shortTitle: PropTypes.string,
  }),
  currentUrl: PropTypes.string,
};

function NavItemDesktop({ item, isScrolling, isTransparent, currentUrl }) {
  const { title, path, shortTitle /* , children */ } = item;

  const pathname = currentUrl;
  const shortPath = path.replace(HOST_WEBSITE, '')

  // const open = false;
  const [open, setOpen] = useState(false);
  const [initDone, setInitDone] = useState(false);
  const navItemRef = useRef();
  const menuPopupRef = useRef();

  const isActiveRoot = pathname && (shortPath === pathname || (shortPath !== '/' && pathname.includes(shortPath)));

  useEffect(() => {
    // This is for the popup not to open again after main menu click
    setInitDone(false);
    setTimeout(() => setInitDone(true));

    if (open) {
      handleCloseMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (!item.children) {
      return undefined;
    }
    const handleOutSideClick = (event) => {
      if (!menuPopupRef.current?.contains(event.target)) {
        handleCloseMenu();
      }
    };
    window.addEventListener('mousedown', handleOutSideClick);
    window.addEventListener('scroll', handleOutSideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutSideClick);
      window.removeEventListener('scroll', handleOutSideClick);
    };
  }, [menuPopupRef, item.children]);

  const handleOpenMenu = () => {
    setOpen(true);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const dotActiveStyle = {
    '&:before': {
      top: 0,
      width: 6,
      height: 6,
      bottom: 0,
      left: -14,
      content: '""',
      display: 'block',
      margin: 'auto 0',
      borderRadius: '50%',
      position: 'absolute',
      backgroundColor: 'accent.main',
    },
  };

  const linkStyle = {
    typography: 'subtitle2',
    fontWeight: 500,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'text.secondary',
    '&:hover': {
      opacity: 0.72,
      textDecoration: 'none',
    },
    ...(isActiveRoot && {
      ...dotActiveStyle,
      color: 'text.primary',
    }),
  };

  return (
    <Box ref={navItemRef} sx={{ position: 'relative' }} onMouseOver={() => initDone && handleOpenMenu()}>
      <NextLink
        key={title}
        href={path}
        passHref
        legacyBehavior
      >
        <Link sx={linkStyle}>
          {shortTitle ? <>
            <Hidden lgUp smDown implementation='css'>{shortTitle}</Hidden>
            <Hidden only='md' implementation='css'>{title}</Hidden>
          </> : title}
        </Link>
      </NextLink>
      {!!item.children &&
      <NavDesktopMenu
        ref={menuPopupRef}
        lists={item.children}
        isOpen={open}
        onClose={handleCloseMenu}
        isScrolling={isScrolling}
        navItemRef={navItemRef}
      />}
    </Box>
  );

  /*
  if (children) {
    return (
      <>
        <RootLinkStyle
          onClick={handleOpenMenu}
          open={open}
          scrolling={isScrolling}
          transparent={isTransparent}
        >
          {title}
          <Iconify
            icon={open ? 'carbon:chevron-up' : 'carbon:chevron-down'}
            sx={{
              ml: 0.5,
              width: 16,
              height: 16,
            }}
          />
        </RootLinkStyle>

        <NavDesktopMenu
          lists={children}
          isOpen={open}
          onClose={handleCloseMenu}
          isScrolling={isScrolling}
        />
      </>
    );
  }
    if (title === 'Documentation') {
      return (
        <RootLinkStyle
          href={path}
          target='_blank'
          rel='noopener'
          scrolling={isScrolling}
          transparent={isTransparent}
        >
          {title}
        </RootLinkStyle>
      );
    }

    return (
      <NextLink key={title} href={path} passHref legacyBehavior>
        <RootLinkStyle active={isActiveRoot} scrolling={isScrolling} transparent={isTransparent}>
          {title}
        </RootLinkStyle>
      </NextLink>
    ); */
}
